var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"p-0"},[_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Profile Settings")]),_c('b-card',{staticClass:"mt-1 mb-1"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-observer',{ref:"profileForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.updateProfile.apply(null, arguments)}}},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"firstName"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required","vid":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"firstName","type":"text","placeholder":"First Name","trim":""},model:{value:(_vm.profileForm.first_name),callback:function ($$v) {_vm.$set(_vm.profileForm, "first_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"profileForm.first_name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"lastName"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required","vid":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"lastName","type":"text","placeholder":"Last Name","trim":""},model:{value:(_vm.profileForm.last_name),callback:function ($$v) {_vm.$set(_vm.profileForm, "last_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"profileForm.last_name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"email","type":"text","placeholder":"Email","trim":"","disabled":""},model:{value:(_vm.profileForm.email),callback:function ($$v) {_vm.$set(_vm.profileForm, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"profileForm.email"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Mobile Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Mobile Phone","rules":"required","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"id":"phone","default-country-code":"CA","preferred-countries":['CA', 'US']},on:{"update":_vm.handleFormatMobile},model:{value:(_vm.phoneNumberInput),callback:function ($$v) {_vm.phoneNumberInput=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phoneNumberInput"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$bvModal.show('change-password-modal')}}},[_vm._v(" Update Password ")])],1)],1),_c('b-col',{staticClass:"form-group text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":invalid || !dirty}},[_vm._v(" Update Profile ")])],1)],1)],1)]}}])})],1)],1)],1)],1)],1),_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Notification Settings")]),_c('b-card',{staticClass:"mt-1 mb-1"},[_c('validation-observer',{ref:"notificationForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.updateNotifications.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Where do you want to receive notifications?","label-for":"notifChannel"}},[_c('validation-provider',{attrs:{"name":"Notification Channel","rules":"required","vid":"notifChannel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"notifChannel","options":_vm.notifOptions},model:{value:(_vm.notificationForm.notification_channel),callback:function ($$v) {_vm.$set(_vm.notificationForm, "notification_channel", $$v)},expression:"notificationForm.notification_channel"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Notification on Close","rules":"required","vid":"notifClose"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"notifClose","name":"check-button","switch":""},model:{value:(_vm.notificationForm.notification_on_close),callback:function ($$v) {_vm.$set(_vm.notificationForm, "notification_on_close", $$v)},expression:"notificationForm.notification_on_close"}},[_vm._v(" Notify me when a question closes ")]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Notification on Win","rules":"required","vid":"notifWin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"notifWin","name":"check-button","switch":""},model:{value:(_vm.notificationForm.notification_on_win),callback:function ($$v) {_vm.$set(_vm.notificationForm, "notification_on_win", $$v)},expression:"notificationForm.notification_on_win"}},[_vm._v(" Notify me when I win a pool ")]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"form-group text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":invalid || !dirty}},[_vm._v(" Update Notification Settings ")])],1)],1)],1)]}}])})],1)],1)],1),_c('b-modal',{attrs:{"id":"change-password-modal","title":"Change Password","hide-footer":""}},[_c('validation-observer',{ref:"passwordChangeForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.updatePassword.apply(null, arguments)}}},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:7|confirmed:confirmPassword","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"password","type":"password","placeholder":"Password","trim":""},model:{value:(_vm.passwordForm.password),callback:function ($$v) {_vm.$set(_vm.passwordForm, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"passwordForm.password"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"confirmPassword"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|min:7|confirmed:password","vid":"confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"confirmPassword","type":"password","placeholder":"Confirm Password","trim":""},model:{value:(_vm.passwordForm.c_password),callback:function ($$v) {_vm.$set(_vm.passwordForm, "c_password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"passwordForm.c_password"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"form-group text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":invalid || !dirty}},[_vm._v(" Update Password ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }