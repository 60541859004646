<template>
  <b-container class="p-0">
    <!-- profile settings -->
    <b-row class="my-2">
      <b-col cols="12">
        <h3>Profile Settings</h3>
        <b-card class="mt-1 mb-1">
          <b-row>
            <b-col md="12">
              <validation-observer
                ref="profileForm"
                #default="{ invalid, dirty }"
              >
                <b-form
                  class="form-container"
                  @submit.prevent="updateProfile"
                >
                  <b-row class="mt-1">
                    <b-col md="6">
                      <b-form-group
                        label="First Name"
                        label-for="firstName"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="First Name"
                          rules="required"
                          vid="firstName"
                        >
                          <b-form-input
                            id="firstName"
                            v-model.trim="profileForm.first_name"
                            type="text"
                            class="form-control"
                            placeholder="First Name"
                            trim
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Last Name"
                        label-for="lastName"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Last Name"
                          rules="required"
                          vid="lastName"
                        >
                          <b-form-input
                            id="lastName"
                            v-model.trim="profileForm.last_name"
                            type="text"
                            class="form-control"
                            placeholder="Last Name"
                            trim
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Email"
                        label-for="email"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="email"
                          vid="email"
                        >
                          <b-form-input
                            id="email"
                            v-model.trim="profileForm.email"
                            type="text"
                            class="form-control"
                            placeholder="Email"
                            trim
                            disabled
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Mobile Phone"
                        label-for="phone"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Mobile Phone"
                          rules="required"
                          vid="phone"
                        >
                          <VuePhoneNumberInput
                            id="phone"
                            v-model.trim="phoneNumberInput"
                            default-country-code="CA"
                            :preferred-countries="['CA', 'US']"
                            @update="handleFormatMobile"
                          />
                          <!--<b-form-input
                            id="phone"
                            v-model.trim="profileForm.mobile"
                            type="text"
                            class="form-control"
                            placeholder="1##########"
                            trim
                          />-->
                          <span class="text-danger">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- <b-col md="12">
                      <b-form-group
                        label="Date of Birth"
                        label-for="dateOfBirth"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Date of Birth"
                          rules="required"
                          vid="dateOfBirth"
                        >
                          <b-input-group class="mb-1">
                            <b-form-input
                              id="dateOfBirth"
                              v-model="dob"
                              type="text"
                              placeholder="YYYY-MM-DD"
                              autocomplete="off"
                            />
                            <b-input-group-append>
                              <b-form-datepicker
                                v-model="dob"
                                show-decade-nav
                                button-only
                                right
                                locale="en-US"
                                aria-controls="dateOfBirth"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                    </b-col> -->

                    <b-col md="12">
                      <b-form-group
                        label="Password"
                        label-for="password"
                      >
                        <b-button
                          variant="outline-primary"
                          @click="$bvModal.show('change-password-modal')"
                        >
                          Update Password
                        </b-button>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      class="form-group text-right"
                    >
                      <b-button
                        variant="primary"
                        type="submit"
                        :disabled="invalid || !dirty"
                      >
                        Update Profile
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- notification settings -->
    <b-row class="my-2">
      <b-col cols="12">
        <h3>Notification Settings</h3>
        <b-card class="mt-1 mb-1">
          <validation-observer
            ref="notificationForm"
            #default="{ invalid, dirty }"
          >
            <b-form
              class="form-container"
              @submit.prevent="updateNotifications"
            >
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Where do you want to receive notifications?"
                    label-for="notifChannel"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Notification Channel"
                      rules="required"
                      vid="notifChannel"
                    >
                      <b-form-select
                        id="notifChannel"
                        v-model="notificationForm.notification_channel"
                        :options="notifOptions"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Notification on Close"
                    rules="required"
                    vid="notifClose"
                  >
                    <b-form-checkbox
                      id="notifClose"
                      v-model="notificationForm.notification_on_close"
                      name="check-button"
                      switch
                    >
                      Notify me when a question closes
                    </b-form-checkbox>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Notification on Win"
                    rules="required"
                    vid="notifWin"
                  >
                    <b-form-checkbox
                      id="notifWin"
                      v-model="notificationForm.notification_on_win"
                      name="check-button"
                      switch
                    >
                      Notify me when I win a pool
                    </b-form-checkbox>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col
                  cols="12"
                  class="form-group text-right"
                >
                  <b-button
                    variant="primary"
                    type="submit"
                    :disabled="invalid || !dirty"
                  >
                    Update Notification Settings
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="change-password-modal"
      title="Change Password"
      hide-footer
    >
      <validation-observer
        ref="passwordChangeForm"
        #default="{ invalid, dirty }"
      >
        <b-form
          class="form-container"
          @submit.prevent="updatePassword"
        >
          <b-row class="mt-1">
            <b-col md="12">
              <b-form-group
                label="Password"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|min:7|confirmed:confirmPassword"
                  vid="password"
                >
                  <b-form-input
                    id="password"
                    v-model.trim="passwordForm.password"
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    trim
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Confirm Password"
                label-for="confirmPassword"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|min:7|confirmed:password"
                  vid="confirmPassword"
                >
                  <b-form-input
                    id="confirmPassword"
                    v-model.trim="passwordForm.c_password"
                    type="password"
                    class="form-control"
                    placeholder="Confirm Password"
                    trim
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="form-group text-right"
            >
              <b-button
                variant="primary"
                type="submit"
                :disabled="invalid || !dirty"
              >
                Update Password
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol,
  BButton, BCard,
  BForm, BFormGroup, BFormInput, BFormCheckbox, BFormSelect,
  // BFormDatepicker, BInputGroup, BInputGroupAppend,
  BModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, confirmed, email, integer, min,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

import useJwt from '@/auth/jwt/useJwt'
import { isUserLoggedIn } from '@/auth/utils'
import HomeBackground from '@/assets/images/backgrounds/home.png'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    // BFormDatepicker,
    // BInputGroup,
    // BInputGroupAppend,
    BModal,

    // form validation
    ValidationProvider,
    ValidationObserver,
    // phone number
    VuePhoneNumberInput,
  },
  data() {
    return {
      phoneNumberInput: '',
      profileForm: {},
      passwordForm: {},
      notificationForm: {},
      notifOptions: [
        { value: null, text: 'Please select an option' },
        { value: 'email', text: 'Email' },
        { value: 'sms', text: 'SMS' },
      ],
      poolsOngoingTableFields: [
        { key: 'date_started', label: 'Date Started' },
        { key: 'cohort_name', label: 'Name' },
        { key: 'starting_count', label: 'Entries' },
        { key: 'current_count', label: 'Survivors' },
        { key: 'prize_total_pennies', label: 'Prize' },
        // { key: 'actions', label: '' },
      ],
      todaysDate: new Date().toJSON().slice(0, 10).replace(/-/g, '/'), // TO DELETE

      // form validation
      email,
      confirmed,
      required,
      integer,
      min,
    }
  },
  computed: {
  },
  created() {
    document.body.style.backgroundImage = `url(${HomeBackground})`
  },
  mounted() {
    if (!isUserLoggedIn()) {
      this.$router.push('/login')
    }

    // this.$store.dispatch('pools/fetchAllOngoingPools')
    this.getCurrentUser()
    this.getNotifSettings()
  },
  methods: {
    /*  GETTERS */

    getCurrentUser() {
      /* get user data */
      useJwt.getCurrentUser()
        .then(userResponse => {
          const userData = userResponse.data.data

          userData.fullName = `${userData.first_name} ${userData.last_name}`
          // userData.mobile = userData.mobile ? userData.mobile.replace('+1', '') : ''
          this.phoneNumberInput = userData.mobile ? userData.mobile : ''

          this.profileForm = { ...this.profileForm, ...userData }

          // localStorage.setItem('userData', JSON.stringify(userData)) // needs to be changed later
        })
        .catch(error => {
          console.log('error', error.response)
        })
    },
    getNotifSettings() {
      /* get notification settings */
      useJwt.getNotifSettings()
        .then(response => {
          const notifData = response.data.data

          this.notificationForm = { ...this.notificationForm, ...notifData }
        })
        .catch(error => {
          console.log('error', error.response)
        })
    },

    /* HANDLERS */

    handleFormatMobile(payload) {
      this.$set(this.profileForm, 'mobile', payload.formattedNumber)
    },

    /* SETTERS */

    updateProfile() {
      /* update profile data */
      const newData = {
        first_name: this.profileForm.first_name,
        last_name: this.profileForm.last_name,
        mobile: this.profileForm.mobile,
      }
      useJwt.updateUser(newData)
        .then(() => {
          // confirm message
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Profile Saved',
              icon: 'BellIcon',
              variant: 'success',
              text: 'Your profile has been updated.',
            },
          })
        })
        .catch(error => {
          console.log('error', error.response)
        })
    },
    updatePassword() {
      this.passwordForm = {
        ...this.passwordForm,
        email: this.profileForm.email,
      }
      /* set notification settings */
      useJwt.updatePassword(this.passwordForm)
        .then(() => {
          this.$bvModal.hide('change-password-modal')
          this.passwordForm = {}

          // confirm message
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Password Saved',
              icon: 'BellIcon',
              variant: 'success',
              text: 'Your password has been updated.',
            },
          })
        })
        .catch(error => {
          console.log('error', error.response)
        })
    },
    updateNotifications() {
      /* set notification settings */
      useJwt.setNotifSettings(this.notificationForm)
        .then(() => {
          // confirm message
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Notification Settings Saved',
              icon: 'BellIcon',
              variant: 'success',
              text: 'Your settings have been updated.',
            },
          })
        })
        .catch(error => {
          console.log('error', error.response)
        })
    },
  },
}
</script>

<style scoped>
.transparent {
  opacity: 0.2;
}
</style>
